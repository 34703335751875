.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layout {
  min-height: 100vh;
  background: var(--background);
  color: var(--text);
  display: flex;
  flex-direction: column;
}

.ant-layout-content {
  flex: 1;
  padding: 0;
  background: var(--background);
}

/* 添加页面过渡效果 */
.section {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 添加滚动平滑效果 */
html {
  scroll-behavior: smooth;
}

/* 优化内容区域的间距 */
.ant-layout-content > section {
  margin: 2rem 0;
  padding: 4rem 0;
}

/* 添加渐变背景 */
.layout {
  background: linear-gradient(
    135deg,
    var(--background) 0%,
    var(--surface) 100%
  );
}

/* 添加响应式padding */
@media (max-width: 768px) {
  .ant-layout-content > section {
    padding: 4rem 0;
  }
}

/* 优化过渡效果 */
* {
  transition: all 0.3s ease-in-out;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 50px;
  background: var(--surface);
  backdrop-filter: blur(10px);
}

.hero-section {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.4));
}

.feature-card {
  background: #1a1a1a;
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.2);
}

/* 配置 Ant Design 主题 */
:root {
  --ant-primary-color: #3b82f6;
  --ant-link-color: #3b82f6;
  --ant-success-color: #52c41a;
  --ant-warning-color: #faad14;
  --ant-error-color: #f5222d;
  --ant-font-size-base: 14px;
  --ant-heading-color: rgba(255,255,255,0.85);
  --ant-text-color: rgba(255,255,255,0.85);
  --ant-component-background: #1a1a1a;
  --ant-body-background: #0d0d0d;
}

.solutions-section {
  padding: 60px 0;
}

.solution-card {
  background: linear-gradient(145deg, #1a1a1a, #222);
  border: none;
  height: 100%;
}

.solution-card:hover {
  background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
}

.solution-list {
  list-style: none;
  padding: 0;
  margin: 16px 0 0;
}

.solution-list li {
  margin: 8px 0;
  padding-left: 20px;
  position: relative;
}

.solution-list li:before {
  content: '•';
  position: absolute;
  left: 0;
  color: #1890ff;
}

.stats-row {
  margin-top: 48px;
}

.stat-card {
  text-align: center;
  background: transparent;
}

.stat-value {
  color: #1890ff !important;
  margin-bottom: 0 !important;
}

.stat-label {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.85);
}

.container-xl {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* 建议的新样式结构 */
.section {
  padding: 5rem 0;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.card {
  background: var(--surface);
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0,0,0,0.2);
}

/* 在你的全局样式文件中添加 */
.reveal .slides section {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.reveal .slides section img {
  margin: 0;
}

/* 添加到你的全局样式文件中 */
.game-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  aspect-ratio: 4/3;
}

.game-canvas {
  width: 100%;
  height: 100%;
  background: #f8fafc;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.video-overlay {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 160px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.stats-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-weight: 500;
  transition: all 0.2s;
}