body {
    @apply bg-gray-900 text-gray-100;
    font-family: 'Inter', sans-serif;
}

section {
    @apply relative overflow-hidden;
}

.section-inner {
    @apply relative z-10;
}

/* 添加渐变背景效果 */
.gradient-bg {
    @apply bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900;
}

.hero-title {
    font-size: 3rem;
    font-weight: bold;
}

.card {
    background: #1a1a1a;
    padding: 2rem;
    border-radius: 0.5rem;
    transition: transform 0.3s;
}

.card:hover {
    transform: translateY(-10px);
}

.container-xl {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 2rem;
}

.header nav a {
    color: var(--text);
}