:root {
    --primary: #2563eb;
    --background: #f8fafc;
    --surface: #ffffff;
    --text: #1e293b;
    --accent: #3b82f6;
}

/* 添加主题过渡动画 */
* {
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* 主题选择器样式 */
.theme-select {
    width: 120px;
    border-radius: 4px;
}

.theme-select-dropdown {
    background: var(--surface);
    border: 1px solid var(--accent);
}