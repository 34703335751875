.section-base {
  @apply py-24 relative overflow-hidden;
}

.section-dark {
  @apply bg-gray-900;
}

.section-light {
  @apply bg-gray-800;
}

.section-title {
  @apply text-4xl font-bold text-center mb-16 text-white;
  position: relative;
}

.section-title::after {
  content: '';
  @apply absolute bottom-0 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-blue-600;
  margin-bottom: -0.75rem;
}

.card {
  @apply bg-gray-800 rounded-xl overflow-hidden shadow-xl transition-all duration-300;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.card:hover {
  @apply transform -translate-y-2 shadow-2xl;
  border-color: rgba(59, 130, 246, 0.5);
}

.card-content {
  @apply p-6;
}

.card-title {
  @apply text-2xl font-bold text-white mb-4;
}

.card-text {
  @apply text-gray-300 leading-relaxed;
}

.container-custom {
  @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

.grid-layout {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8;
}

.bg-gray-900 {
  background-color: #111827;
}

.text-center {
  text-align: center;
}

.hero-section {
  text-align: center;
  padding: 5rem 0;
}

.ant-typography {
  color: var(--text);
}

.ant-typography.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

/* 移动端适配基础样式 */
@media (max-width: 768px) {
  .container-xl {
    padding: 0 1rem;
  }

  .section {
    padding: 3rem 0;
  }

  .grid-layout {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .card {
    margin: 0 1rem;
  }

  .hero-title {
    font-size: 2rem;
  }

  .section-title {
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }
}

/* 添加移动端菜单过渡动画 */
.mobile-menu-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.mobile-menu-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms;
}

.mobile-menu-exit {
  opacity: 1;
  transform: translateY(0);
}

.mobile-menu-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 200ms, transform 200ms;
}

.demo-showcase {
  padding: 40px 20px;
  background: #f5f5f5;
}

.demo-card {
  text-align: center;
  transition: all 0.3s ease;
}

.demo-card:hover {
  transform: translateY(-5px);
}

.demo-content {
  margin-top: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  position: relative;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2em;
  color: #333;
} 